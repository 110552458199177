@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.document-sign-rt{
	margin-left: -15px;
	margin-right: -15px;
	.modal-sign-password{
		text-align: center;
		.modal-icon{
			@include borderRadius(100%);
			border: 1px solid;
			padding: 10px;
			color: $primary-color;
		}
	}
	.modal-sign-feedback{

		.button-action{
			text-align: center;
		}
	}
	.document-sign-preview{
		align-items: center;
		text-align: center;
		img{
			margin-bottom: 30px;
			max-width: 100%;
		}
	}
	.documento-sign-rt-action{
		line-height: 80px;
		//background-color: $def-color-light;
		background-color: #54616D;
		
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		text-align: center;
		padding-right: 20px;
		.document-status{
			color: #FFFFFF;
			text-align: center;
		}
		.icon-status-sign{
			font-size: 18px;
		}
	}
}