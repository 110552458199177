@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.document-status{	
	@include componentDefaultFonts();
	@include clearfix();
	.document-status-icon{
		float: left;
		line-height: 30px;
		margin-right: 10px;
	}
	.document-status-description{
		float: left;
		
			.document-status-name{
				font-weight: bold;
				margin-top: 6px;
			}
			.document-status-last-update{
				font-size: 11px;
				color: lighten($primary-font-color, 30%);
		
			}
	}

	&.signed{
		.document-status-icon{
			color: $green-color-light;
		}
	}

	&.canceled, &.refused{
		.document-status-icon{
			color: $red-color-light;
		}
	}
}