@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.document-new-rt{
	@include componentDefaultFonts();

	.document-new-rt-upload{
		background-color: #f2f2f2;
		padding: 10px;
		margin-bottom: 40px;
	}
	.document-new-rt-destinations{

		.input-rt{
				margin: 0px !important;
		}

		.actions{
			button{
				margin-left: 10px;
			}
		}

	}
	.document-new-rt-actions{
		text-align: right;
		margin-top: 20px;
		.button{
			margin-right: 10px;
		}
	}

	.modal-new-feedback{

		.button-action{
			text-align: center;
		}
	}

	th{
		text-align: center;
	}
}