@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';
.document-list{
	@include componentDefaultFonts();
	.document-type{
		color: $primary-color-light;
	}
	table{
		border: none;
	}
	.filter-actions{
		margin-top: 20px
	}
	.panel-documents{
		>div{
			@include clearfix();
		}
		.actions{
			float: right;
			margin-bottom: 20px;
		}
		.action-perpage{
			float: right;
		}
	}
	.pagination{
		float: right;
	}

	.subscriber{
		
		margin-bottom: 10px;
	}
	
}
