.document-show-rt{

	.signature-item{
		margin-bottom: 10px;
		.signature-actions{
			float: right;
		}
	}

	.file-preview{
		position: relative;
		display: block;
		background-color: #FFFFFF;
		height: 100%;
		width: 100%;
		min-height: 800px;
		max-height: 800px;
		overflow-y: auto;
		text-align: center;
		img{
  			max-width: 100%;
		}
	}

	.modal-resend-actions{
		text-align: center;
	}
	.document-detail{
		dt{
			font-weight: 600;
		}
		dd{
			margin: 0px;
			margin-bottom: 10px;
		}
	}
}